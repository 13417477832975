import Cookies from 'js-cookie'

export default class ABCookieManager {
  static getInstance() {
    if (!this.instance) {
      this.instance = new ABCookieManager()
    }
    return this.instance
  }

  getAll() {
    return Cookies.get()
  }

  read(name) {
    return Cookies.get(name)
  }

  create(name, val = '', expiringDays = 7) {
    Cookies.set(name, val, { expires: expiringDays, sameSite: 'lax' })
  }

  remove(name) {
    Cookies.remove(name)
  }

  // Feel free to extend this class if needed
}
