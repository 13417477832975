/* global $ window */

import '../../../javascript/jquery'
import LayoutVariables from '../../../javascript/src/config/layout_variables'
import Events from '../../../javascript/src/config/events'

require('stickUp')

export default class NavigationWidget {
  get navigationNs() {
    return '.navigation'
  }

  get navigationToggle() {
    return '.js-toggle-navigation'
  }

  get navigationActiveClass() {
    return 'navigation-links-container--open'
  }

  get searchToggle() {
    return '.navigation-search-bar'
  }

  get navigationMainLink() {
    return '.navigation__main-link'
  }

  get navigationItem() {
    return '.navigation__item'
  }

  get navigationFixedSelector() {
    return '.js-navigation-fixed'
  }

  get navigationItemActiveClass() {
    return 'navigation__item--active'
  }

  get toggleWarningButton() {
    return '.js-toggle-warning'
  }

  get warningContainer() {
    return '.js-unauthorized-content-warning-container'
  }

  get navigationSubMenu() {
    return '.js-navigation-links-container'
  }

  get openRegisterModal() {
    return '.js-open_register_modal'
  }

  constructor() {
    this.$ns = $(this.navigationNs)
    this.$navLinks = $(this.navigationSubMenu)
    this.$window = $(window)
    this.$toggleWarningButton = $(this.toggleWarningButton)
    this.$warningContainer = $(this.warningContainer)
    this.$searchToggle = $(this.searchToggle)

    this.initBehavior()
  }

  initBehavior() {
    this.toggleWarning()

    $(this.openRegisterModal).on('click', this.onRegisterClick.bind(this))

    this.$ns.find(this.navigationItem).on('click', this.navigationMainLink, this.onOpenSubmenu.bind(this))

    this.$ns.find(this.navigationToggle).on('click', () => {
      if (this.$navLinks.hasClass(this.navigationActiveClass)) {
        this.hideNavigation()
      } else {
        this.showNavigation()
      }
    })

    $(this.navigationFixedSelector).stickUp()
  }

  onRegisterClick() {
    this.hideNavigation()
  }

  hideNavigation() {
    this.$navLinks.removeClass(this.navigationActiveClass)
    this.$ns.trigger(Events.ON_NAVIGATION_CLOSE)
  }

  showNavigation() {
    this.$navLinks.addClass(this.navigationActiveClass)
    this.$ns.trigger(Events.ON_NAVIGATION_OPEN)
  }

  onOpenSubmenu(event) {
    const width = this.$window.width()
    const currentLink = $(event.currentTarget)

    if (width < LayoutVariables.desktopWidth) {
      event.preventDefault()
      const parentItem = currentLink.parents(this.navigationItem)

      parentItem.toggleClass(this.navigationItemActiveClass)
    }
  }

  toggleWarning() {
    this.$toggleWarningButton.on('click', () => {
      const width = this.$window.width()
      if (width >= LayoutVariables.tabletWidth && width < LayoutVariables.desktopWidth) {
        this.$warningContainer.toggleClass('navigation__unauthorized-container--active')
      }
    })
  }
}
