import ViewportHelper from './viewport_helper'
import GeolocationHelper from './geolocation_helper'

/* global $ */

const AUTOCOMPLETE_NS = '.js-autocomplete'
const AUTOCOMPLETE_CONTAINER = `${AUTOCOMPLETE_NS}__container`
const AUTOCOMPLETE_BOX = `${AUTOCOMPLETE_NS}__box`
const WHERE_INPUT = '.js-autocomplete-where'
const AUTOCOMPLETE_LINK = '.js-autocomplete__link'

export default class Autocomplete {
  constructor() {
    this.viewportHelper = ViewportHelper.getInstance()
    this.geolocationHelper = GeolocationHelper.getInstance()
    this.activeRequests = []
    this.selectedWhere = true

    this.geoSearchSuggester()
    this.searchSuggester()
  }

  geoSearchSuggester() {
    $(WHERE_INPUT).on('click', event => {
      // kind of hackish test to look for a dom-object to do stuff.
      const $clearButton = $(event.target).parents('.js-clear-input')
      // if the event was triggered by the form, open the suggestions!
      const targetNodeName = $(event.target)[0].nodeName.toLowerCase()
      const focusFromInput = targetNodeName === 'input'

      const $activeDataInput = $(event.currentTarget).find('.js-search-where')
      const $ns = $activeDataInput.parents(AUTOCOMPLETE_NS)
      const $activeContainer = $ns.find(AUTOCOMPLETE_CONTAINER)

      if ($activeDataInput.val().length > 0 && !this.selectedWhere) {
        $activeContainer.show()
      } else {
        const lastCities = $activeDataInput.data('cities')
        const dataUrl = lastCities.url
          || (() => {
            throw new Error('Missing URL Parameter')
          })()

        const onLoadData = content => {
          if (!$clearButton || focusFromInput) {
            this.displayContainer($activeDataInput, content)
          }
        }

        $.ajax({
          url: dataUrl
        }).done(onLoadData.bind(this))

        if (this.shouldHideContainer(event)) {
          $activeContainer.hide()
        }
      }

      $activeContainer.on('click', locationClick => {
        locationClick.preventDefault()
        locationClick.stopPropagation()
        if (locationClick.target.className === 'js-autocomplete__link-text') {
          this.geolocationHelper.getCoordinates()
        }
      })

      this.containerEvents($activeContainer, $activeDataInput)
    })
  }

  searchSuggester() {
    const $dataInput = $('input[data-autocomplete]')
    let timeoutHandler = null

    $dataInput.on('keyup', event => {
      const $activeDataInput = $(event.currentTarget)
      const $ns = $activeDataInput.parents(AUTOCOMPLETE_NS)
      const $activeContainer = $ns.find(AUTOCOMPLETE_CONTAINER)

      if (timeoutHandler) {
        clearTimeout(timeoutHandler)
      }

      if (this.shouldHideContainer(event)) {
        $activeContainer.hide()
      }

      const autocomplete = $activeDataInput.data('autocomplete')
      const dataUrl =
        autocomplete.url ||
        (() => {
          throw new Error('Missing URL Parameter')
        })()

      const userInput = $activeDataInput.val()
      const dataObject = autocomplete.data || {}
      dataObject.q = userInput

      const onLoadData = content => {
        this.displayContainer($activeDataInput, content)
      }

      const timeoutCallback = () => {
        if (this.shouldSendRequest(userInput)) {
          while (this.activeRequests.length > 0) {
            this.activeRequests.pop().abort()
          }

          const currentRequest = $.ajax({
            url: dataUrl,
            data: dataObject
          }).done(onLoadData.bind(this))

          this.activeRequests.push(currentRequest)

          if ($activeDataInput.hasClass('js-search-where')) {
            this.selectedWhere = false
          }
        }
      }

      timeoutHandler = setTimeout(timeoutCallback, 250)

      this.containerEvents($activeContainer, $activeDataInput)
    })
  }

  containerEvents($container, $input) {
    $container.on('click', `${AUTOCOMPLETE_BOX} [data-fill-in]`, clickEvent => {
      $input.val($(clickEvent.currentTarget).data('fill-in'))
      $input.trigger('change')
      $container.hide()

      const disabledFormSubmit = $input.attr('data-disable-autocomplete-form-submit')

      if (disabledFormSubmit !== 'true') {
        $input.get(0).form.submit()
      } else if ($input.hasClass('js-search-where')) {
        this.selectedWhere = true
      }
    })

    let autocompleteCloseTimeout = null
    let focusTimeout = null

    $container.on('mouseenter', () => {
      if (autocompleteCloseTimeout) {
        clearTimeout(autocompleteCloseTimeout)
      }
    })

    $container.on('mouseleave', () => {
      autocompleteCloseTimeout = setTimeout(() => {
        $(AUTOCOMPLETE_CONTAINER).hide()
      }, 500)
    })

    $input.on('focusin', () => {
      if (focusTimeout) {
        clearTimeout(focusTimeout)
      }
    })

    $input.on('focusout', () => {
      focusTimeout = setTimeout(() => {
        $container.hide()
      }, 250)
    })

    $input.on('keyup', event => {
      if (event.key === 'Enter') {
        $(AUTOCOMPLETE_CONTAINER).hide()
      }
    })
  }

  displayContainer($input, content) {
    const $ns = $input.parents(AUTOCOMPLETE_NS)
    const $container = $ns.find(AUTOCOMPLETE_CONTAINER)

    if (
      (content && $(content).find('li').length > 0) ||
      $(content).find(AUTOCOMPLETE_LINK).length > 0
    ) {
      $container.html(content).show()
    } else {
      $container.hide()
    }
  }

  shouldSendRequest(userInput) {
    const oldInput = this.currentInput
    this.currentInput = userInput

    return userInput.length && oldInput !== userInput
  }

  shouldHideContainer(event) {
    return event.key === 'Escape' || $(event.currentTarget).val().length === 0
  }
}
