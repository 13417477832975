/* global $ */

import Events from '../config/events'
import { hideNavBackdrop, showNavBackdrop } from '../lib/navigation_backdrop_helper'
import registerOutsideClickListener from '../lib/outside_event_listener'

export default class NavigationManager {
  constructor() {
    this.$html = $('html')

    this.initBehavior()
  }

  initBehavior() {
    $('body')
      .on(Events.ON_NAVIGATION_OPEN, this.openNavigation.bind(this))
      .on(Events.ON_NAVIGATION_CLOSE, this.closeNavigation.bind(this))
      .on(Events.ON_NAVIGATION_SEARCH_OPEN, this.openSearch.bind(this))
      .on(Events.ON_NAVIGATION_SEARCH_CLOSE, this.closeSearch.bind(this))
  }

  openSearch() {
    this.beforeHook('navigation-links-container--open')
    this.afterHook(
      this.closeSearch.bind(this)
    )
    this.colorizeNavigation()
  }

  closeSearch() {
    this.hideBackdrop()
    this.closeElem('js-navigation-search-bar--open')
  }

  openNavigation() {
    this.beforeHook('js-navigation-search-bar--open')
    this.resetNavigationColor()
    this.afterHook(
      this.closeNavigation.bind(this)
    )
    this.colorizeNavigation()
  }

  closeNavigation() {
    this.hideBackdrop()
    this.closeElem('navigation-links-container--open')
  }

  colorizeNavigation() {
    if ($('.navigation').hasClass('navigation--fixed')) {
      $('.navigation__login').css('background-color', '#8B8B8B')
      $('.navigation__link-text').css('background-color', '#8B8B8B')
      $('.navigation').css('background-color', '#8B8B8B')
      $('.breadcrumbs').css('background-color', '#8B8B8B')
    }
  }

  resetNavigationColor() {
    if ($('.navigation').hasClass('navigation--fixed')) {
      $('.navigation__login').css('background', '#f5f5f5')
      $('.navigation__link-text').css('background', '#f5f5f5')
      $('.navigation').css('background-color', '#fff')
      $('.breadcrumbs').css('background-color', '#fff')
    }
  }

  hideBackdrop() {
    this.resetNavigationColor()
    hideNavBackdrop()
    this.allowScroll()
  }

  closeElem(openClass) {
    const $openNavElem = $(`.${openClass}`)

    if ($openNavElem.length) {
      $openNavElem.removeClass(openClass)
    }
  }

  beforeHook(className) {
    hideNavBackdrop()
    this.closeElem(className)
  }

  afterHook(callback) {
    registerOutsideClickListener('.navigation__row--general', () => {
      this.resetNavigationColor()

      callback()
    })

    showNavBackdrop()
    this.blockScroll()
  }

  blockScroll() {
    this.$html.css('overflow', 'hidden')
  }

  allowScroll() {
    this.$html.css('overflow', 'scroll')
  }
}
