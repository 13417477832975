/* global $ */

const PASSWORD_FIELD = '.password-field'
const PASSWORD_FIELD_INPUT = '.password-field__input'
const PASSWORD_FIELD_TOGGLE_CLASS = 'password-field--show-text'
const PASSWORD_FIELD_ICON_SELECTOR = '.password-field__icon'

export default class PasswordFieldWidget {
  constructor(namespace = null) {
    let passwordSelector = PASSWORD_FIELD

    if (namespace) {
      passwordSelector = `${namespace} ${passwordSelector}`
    }

    this.$passwordField = $(passwordSelector)

    this.$formElement = this.$passwordField.find(PASSWORD_FIELD_INPUT)
    this.$icon = this.$passwordField.find(PASSWORD_FIELD_ICON_SELECTOR)
    this.initBehavior()
  }

  getPasswordField() {
    return this.$passwordField
  }

  getPasswordFormElement() {
    return this.$formElement
  }

  initBehavior() {
    this.$icon.on('click', this.showText.bind(this))
  }

  showText() {
    this.toggleIcon()
    this.changeInputType()
  }

  toggleIcon() {
    this.$passwordField.toggleClass(PASSWORD_FIELD_TOGGLE_CLASS)
  }

  changeInputType() {
    let type = 'password'

    if (this.$formElement.attr('type') === 'password') {
      type = 'text'
    }

    this.$formElement.attr({ type })
  }
}
