const SEARCH_WHERE_INPUT = '.js-search-where'
const SEARCH_FORM = '.js-navigation-search-bar-form'
const SEARCH_FORM_NEW = '#new_form_main_search'

export default class GeolocationHelper {
  constructor() {
    this.$searchWhereInput = $(SEARCH_WHERE_INPUT)
    this.$searchForm = $(SEARCH_FORM)
    this.$searchFormNew = $(SEARCH_FORM_NEW)
    this.$setSearchGeocodeURL = $(SEARCH_WHERE_INPUT).attr('data-set-search-geocode-url')
    this.$removeSearchGeocodeURL = $(SEARCH_WHERE_INPUT).attr('data-remove-search-geocode-url')
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new GeolocationHelper()
    }
    return this.instance
  }

  // return latitude and longitude
  getCoordinates() {
    function onGeolocateSuccess(coordinates) {
      const { latitude, longitude } = coordinates.coords

      window.dataLayer.push({ 'location-permission': 'true' })

      $.ajax({
        url: this.$setSearchGeocodeURL,
        data: {
          latitude,
          longitude,
        },
      }).done((data) => {
        const { city } = data
        // lat/long as actual "Standort" not city is used
        this.$searchWhereInput.val('')
        this.$searchWhereInput.attr('placeholder', 'Mein Standort')
        this.$searchWhereInput.attr('data-location', city)

        // Try to search with new search form first
        // to respect set filters and arguments;
        // try old one as backup
        if (this.$searchFormNew.length) {
          this.$searchFormNew.submit()
        } else if (this.$searchForm.length) {
          this.$searchForm.submit()
        }
      })
    }

    function onGeolocateError(error) {
      if (error.code === 1) {
        // they said no
        window.dataLayer.push({ 'location-permission': 'false' })
      } else if (error.code === 2) {
        // position unavailable
      } else if (error.code === 3) {
        // timeout
      }
      $.ajax({
        url: this.$removeSearchGeocodeURL,
      }).done(() => {
        this.$searchWhereInput.val('')
      })
    }

    if (window.navigator && window.navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onGeolocateSuccess.bind(this), onGeolocateError.bind(this))
    }
  }
}
