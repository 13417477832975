/* global $ */
import AbPubSub from '../../javascript/src/lib/ab_pubsub'
import Events from '../../javascript/src/config/events'

const eventMappings = {
  'Du folgst jetzt diesem Unternehmen.': 'company_subscription',
  'Du folgst dem Unternehmen nun nicht mehr.': 'company_subscription_deleted',
  'Jobletter erfolgreich erstellt.': 'search_subscription',
  'Jobletter wurde entfernt.': 'search_subscription_deleted',
  'Diese Suche wurde abonniert. Du erhältst ab sofort passende Stellen per E-Mail.': 'search_subscription',
  'Diese Suche wurde abbestellt.': 'search_subscription_deleted',
  'Ihre Anfrage wurde erfolgreich versendet. Wir werden Sie zeitnah kontaktieren.': 'contact_form_send',
}

export default class FlashMessagesWidget {
  get containerSelector() {
    return '.flash-messages'
  }

  get alertSelector() {
    return '.alert'
  }

  get autoCloseSelector() {
    return '.alert--success'
  }

  get ttl() {
    return 4000
  }

  constructor() {
    this.pubSub = AbPubSub.getInstance()

    this.init()
    this.subscribeEvents()
  }

  init() {
    const $container = $(this.containerSelector)
    const $alert = $container.find(this.alertSelector)
    const $alerts = $container.find(this.alertSelector)

    const totalAlerts = $alerts.length
    document.documentElement.style.setProperty('--total-alerts', totalAlerts)

    $alerts.each((index, alert) => {
      this.animateAlert($(alert))
      this.pushToDataLayer($(alert))
    })

    $alerts.on('click', this.onClickClose.bind(this))
    this.setAutoCloseTimer()
    this.show($alert)
  }

  setAutoCloseTimer() {
    const $autoCloseAlerts = $(this.autoCloseSelector)

    $autoCloseAlerts.each((index, alert) => {
      this.setAutoCloseTimeout($(alert))
    })
  }

  setAutoCloseTimeout($alert) {
    setTimeout(() => {
      this.close($alert)
    }, this.ttl)
  }

  animateAlert($alert) {
    $alert.addClass('show')
  }

  onClickClose(event) {
    this.close($(event.currentTarget))
  }

  onAutoClose() {
    const $container = $(this.containerSelector)
    const $alerts = $container.find(this.autoCloseSelector)

    $alerts.each((index, alert) => {
      this.close($(alert))
    })
  }

  close($alert) {
    $alert.addClass('closing')

    setTimeout(() => {
      $alert.removeClass('show')
      $alert.remove()
    }, 500)
  }

  show($elem) {
    $elem.fadeIn()
  }

  createAlert(message, type) {
    const $alert = $('<div>', {
      class: `alert alert--${type} js-alert-${type}`,
      html: $('<div>', {
        class: 'alert__container',
        html: [
          $('<i>', { class: 'alert__icon' }),
          $('<div>', {
            class: 'alert__text',
            text: message,
          }),
          $('<i>', { class: 'alert__close' }),
        ],
      }),
    })
    $(this.containerSelector).append($alert)

    return $alert
  }

  showErrorMessage(message) {
    this.createAlert(message, 'alert')
    this.init()
  }

  showSuccessMessage(message) {
    this.createAlert(message, 'success')
    this.init()
  }

  subscribeEvents() {
    this.pubSub.subscribe(Events.ON_SHOW_ERROR_MESSAGE, (message) => {
      this.showErrorMessage(message)
    })

    this.pubSub.subscribe(Events.ON_SHOW_SUCCESS_MESSAGE, (message) => {
      this.showSuccessMessage(message)
    })
  }

  pushToDataLayer($alert) {
    Object.entries(eventMappings).forEach(([key, eventName]) => {
      if ($alert.text().includes(key)) {
        window.dataLayer.push({ event: eventName })
      }
    })
  }
}
