/* global $ */
export default class MegateaserCarouselWidget {
  get carouselMainSelector() {
    return '.js-megateaser-carousel-main'
  }

  get carouselNavSelector() {
    return '.js-megateaser-carousel-nav'
  }

  get carouselPrevSelector() {
    return '.js-carousel-navigation-left'
  }

  get carouselNextSelector() {
    return '.js-carousel-navigation-right'
  }

  get carouselCardSelector() {
    return '.js-carousel-card'
  }

  constructor() {
    // Must be executed before initalization
    this.initCarousel()
    this.handleArrows()
  }

  initCarousel() {
    this.$carousel = $(this.carouselMainSelector).slick({
      lazyLoad: 'progressive',
      asNavFor: this.carouselNavSelector,
      dots: false,
      slidesToShow: 1,
      fade: true,
      autoplay: true,
      autoplaySpeed: 7000,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1800,
          settings: {
            fade: true,
            arrows: false,
            slidesToShow: 1
          }
        },
        {
          breakpoint: 1200,
          settings: {
            fade: false,
            arrows: false,
            slidesToShow: 3,
            variableWidth: true,
            centerMode: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            fade: false,
            slidesToShow: 3,
            variableWidth: true,
            centerMode: true
          }
        }
      ]
    })

    $(this.carouselNavSelector).slick({
      asNavFor: this.carouselMainSelector,
      dots: false,
      slidesToShow: 3,
      variableWidth: true,
      focusOnSelect: true,
      centerMode: true,
      arrows: false
    })
  }

  handleArrows() {
    this.$arrowPrev = $(this.carouselPrevSelector)
    this.$arrowNext = $(this.carouselNextSelector)
    this.$arrowPrev.on('click', this.onArrowClick.bind(this, 'Prev'))
    this.$arrowNext.on('click', this.onArrowClick.bind(this, 'Next'))
  }

  onArrowClick(arrow) {
    return this.$carousel.slick(`slick${arrow}`)
  }

  slideFromIndex(slickIndex) {
    return this.$carousel.find(`[data-slick-index='${slickIndex}']`)
  }
}
