/* global $ document */

import cookie from 'js-cookie'
import Events from '../../javascript/src/config/events'
import AbstractScrollTo from '../../javascript/src/lib/abstract_scrollto'
import ABPubSub from '../../javascript/src/lib/ab_pubsub'

const MOVE_TO_TOP_BTN = '.move-to-top-button'
const TOP = 0

export default class MoveToTopButton extends AbstractScrollTo {
  constructor() {
    super()
    this.pubSub = ABPubSub.getInstance()
    this.$btn = $(MOVE_TO_TOP_BTN)
    this.init()
  }

  init() {
    // check cookie when it is available move button to bottom
    if (cookie.get('overlayHidden')) {
      this.$btn.css('bottom', '1.5rem')
    }

    const $doc = $(document)
    let lastScrollTop = 0

    $doc.on('scroll', () => {
      const st = $doc.scrollTop()
      if (st > lastScrollTop) {
        this.$btn.fadeOut(300)
      } else if ($doc.scrollTop() > 800 && $doc.scrollTop() < $doc.height() - 1100) {
        this.$btn.fadeIn(150)
      } else {
        this.$btn.fadeOut(300)
      }
      lastScrollTop = st
    })

    this.$btn.on('click', () => {
      this.scrollTo(TOP, 'slow')
      this.pubSub.emit(Events.ON_SCROLL_TOP)
    })

    const overlayClose = document.querySelector('.close-overlay')
    if (overlayClose) {
      overlayClose.addEventListener(Events.ON_CLOUD_MARKETING_OVERLAY_CLOSE, () => {
        this.$btn.animate({ bottom: '1.5rem' })
      })
    }
  }
}
