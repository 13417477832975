export default function registerOutsideClickListener(elementSelector, callback) {
  const outsideClickListener = event => {
    event.stopPropagation()
    const outsideClickTargetElem = document.querySelector(elementSelector)

    if (!event.composedPath().includes(outsideClickTargetElem)) {
      callback()
      document.removeEventListener('click', outsideClickListener)
    }
  }

  document.addEventListener('click', outsideClickListener)
}
