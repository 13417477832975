/* global $ */

const SEARCH_NAMESPACE = '.main-search-bar'

export default class MainSearchBar {
  constructor() {
    this.initTrackingFields()
  }

  initTrackingFields() {
    const whatField = $(`${SEARCH_NAMESPACE} #main-search-bar-what`)
    const whereField = $(`${SEARCH_NAMESPACE} #main-search-bar-where`)
    const whatTrackingField = $('#t_main-search-bar-what')
    const whereTrackingField = $('#t_main-search-bar-where')
    const listenTo = 'change keyup blur'

    whatField.on(listenTo, () => {
      this.copyValueTo(whatTrackingField, whatField.val())
    })

    whereField.on(listenTo, () => {
      this.copyValueTo(whereTrackingField, whereField.val())
    })
  }

  copyValueTo(field, value) {
    field.val(value)
  }
}
