/* eslint-disable operator-linebreak */

import transformURL from './dataLayerURLShortener'
import AbPubSub from './ab_pubsub'
import Events from '../config/events'
import ABCookieManager from './ab_cookie_manager'

const NO_ID_FOUND = 'no vacancy id found'

export default class DatalayerPusher {
  constructor() {
    this.pubSub = AbPubSub.getInstance()
    this.jobPostings = null
    this.registerListener()
    this.vacancyIds = new Set()
  }

  registerListener() {
    this.pubSub.subscribe(Events.ON_JOB_POSTINGS_LOADED, this.handleNewJobPostings.bind(this))
    this.pubSub.subscribe(Events.ON_SHOW_VACANCY, this.handleShowVacancy.bind(this))
  }

  // Job Posting Cards
  handleNewJobPostings({ parentSelector, selector, prefix, origin = 'search' }) {
    this.jobPostings = document.querySelectorAll(`${parentSelector} ${selector}`)
    this.filterJobPostings(this.jobPostings)
    if (this.jobPostings.length === 0) {
      return
    }
    this.createJobPostingChunks(this.jobPostings, prefix, origin)
    this.attachJobPostingClickEvents(this.jobPostings, prefix, origin)
  }

  filterJobPostings(jps) {
    const visibleJobPostings = []
    jps.forEach((jp) => {
      if (this.checkVisibility(jp.parentElement)) {
        visibleJobPostings.push(jp)
      }
    })
    this.jobPostings = visibleJobPostings
  }

  // need to do this because of browser compatibility for safari < 17.4
  // as time goes on we could use web apis checkVisibility() Method
  checkVisibility(elem) {
    if (window) {
      const style = window.getComputedStyle(elem)
      return style.display !== 'none' && style.visibility !== 'hidden'
    }
    return false
  }

  createJobPostingChunks(jobPostings, prefix) {
    const items = []
    jobPostings.forEach((jp, index) => {
      if (this.vacancyIds.has(jp.dataset.publicId)) {
        return
      }
      items.push(this.extractVacancyId(jp.dataset, index, prefix, origin))
      this.vacancyIds.add(jp.dataset.publicId)
    })

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        currency: 'EUR',
        items,
      },
    })
  }

  attachJobPostingClickEvents(jobPostings, prefix, origin) {
    jobPostings.forEach((jobPosting) => {
      jobPosting.parentElement.addEventListener('click', () => {
        const items = [this.extractVacancyId(jobPosting.dataset, 0, prefix, origin)]
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: 'select_item',
          ecommerce: {
            currency: 'EUR',
            items,
          },
        })
        this.writeInformationToLocalStorage(items[0])
      })
    })
  }

  writeInformationToLocalStorage(item) {
    // eslint-disable-next-line camelcase
    const { item_id, item_list_id, item_list_name, item_tags } = item
    const datalayerInfo = {
      item_list_id,
      item_list_name,
      item_tags,
    }

    const cookie = ABCookieManager.getInstance()
    const consentCookie = cookie.read('CookieConsent')

    // Cookie comes back as string and cannot be converted to object or json
    // Therefore we need to work with the string
    const marketing = 'marketing:'
    const index = consentCookie.lastIndexOf(marketing) + marketing.length
    const consent = consentCookie.substring(index, index + 4)

    // eslint-disable-next-line camelcase
    if (consent === 'true') localStorage.setItem(item_id, JSON.stringify(datalayerInfo))
  }

  getItemIdFromViewItemEvent(dataLayer) {
    const viewItemEvent = dataLayer.find((item) => item.event === 'view_item')

    if (
      viewItemEvent &&
      viewItemEvent.ecommerce &&
      viewItemEvent.ecommerce.items &&
      viewItemEvent.ecommerce.items.length > 0
    ) {
      return viewItemEvent.ecommerce.items[0].item_id
    }

    return NO_ID_FOUND
  }

  extractVacancyId(jobPostingDataSet, index, prefix, origin) {
    let listIdAndName = transformURL(window.location.href)
    const vacancyId = this.getItemIdFromViewItemEvent(window.dataLayer)
    if (origin !== 'search' && vacancyId !== NO_ID_FOUND) {
      listIdAndName = vacancyId
    }
    listIdAndName = `${prefix}${listIdAndName}`

    return {
      index,
      item_list_id: listIdAndName,
      item_list_name: listIdAndName,
      item_id: jobPostingDataSet.publicId,
      item_name: jobPostingDataSet.jobPostingTitle,
      item_brand: jobPostingDataSet.corporationId,
      item_category: 'Job-Posting',
      item_category2: jobPostingDataSet.vacancyCityName,
      item_category3: jobPostingDataSet.corporationName,
      item_category4: jobPostingDataSet.jobPostingProfession,
      item_category5: jobPostingDataSet.subsidiaryName,
      item_application_options: jobPostingDataSet.itemApplicationOptions,
      item_status: jobPostingDataSet.corporationSalesforceCategory,
      item_tags: jobPostingDataSet.tags || '',
      item_sub_id: jobPostingDataSet.subsidiaryPublicId,
      price: 1,
      quantity: 1,
    }
  }

  // Vacancie Page
  handleShowVacancy(datalayerInfo) {
    window.dataLayer.push({
      corporation: datalayerInfo.corporation,
      'position-count': datalayerInfo['position-count'],
    })

    const storedInfo = this.checkLocalStorage(datalayerInfo.item_id)
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        currency: 'EUR',
        items: [
          {
            index: 0,
            item_id: datalayerInfo.item_id,
            item_name: datalayerInfo.item_name,
            item_brand: datalayerInfo.item_brand,
            item_category: datalayerInfo.item_category,
            item_category2: datalayerInfo.item_category2,
            item_category3: datalayerInfo.item_category3,
            item_category4: datalayerInfo.item_category4,
            item_category5: datalayerInfo.item_category5,
            item_status: datalayerInfo.item_status,
            item_application_options: datalayerInfo.item_application_options,
            price: datalayerInfo.price,
            quantity: datalayerInfo.quantity,
            item_list_name: storedInfo !== null ? storedInfo.item_list_name : '',
            item_list_id: storedInfo !== null ? storedInfo.item_list_id : '',
            item_tags: storedInfo !== null ? storedInfo.item_tags : '',
            item_sub_id: datalayerInfo.item_sub_id,
          },
        ],
      },
    })
  }

  checkLocalStorage(itemId) {
    const info = localStorage.getItem(itemId)
    if (info !== null) localStorage.removeItem(itemId)
    return JSON.parse(info)
  }
}
