export default {
  // global events:
  ON_AB_RESIZE: 'ab.onResize',
  ON_AB_SCROLL: 'ab.onScroll',
  ON_AB_KEYPRESS: 'ab.onKeypress',
  ON_APPEND_DYNAMIC_INPUT: 'ab.onAppendDynamicInput',
  //
  ON_NAVIGATION_OPEN: 'ab.onNavigationOpen',
  ON_NAVIGATION_CLOSE: 'ab.onNavigationClose',
  ON_NAVIGATION_SEARCH_OPEN: 'ab.onNavigationSearchOpen',
  ON_NAVIGATION_SEARCH_CLOSE: 'ab.onNavigationSearchClosex',
  //
  ON_SEARCH_DONE: 'ab.onSearchDone',
  ON_SEARCH_RADIUS_CHANGE: 'ab.onSearchRadiusChange',
  ON_SEARCH_FORM_INPUT_CHANGE: 'ab.onSearchFormInputChange',
  ON_CLOUD_MARKETING_OVERLAY_CLOSE: 'ab.onYoutubeOverlayClose',
  ON_APPLICATION_BUTTON_CLICKED: 'ab.onApplicationButtonClick',
  // Direct application events
  ON_DA_SECTION_CHANGE: 'ab.da.onSectionChange',
  ON_DA_FORM_DISABLE: 'ab.da.onFormDisable',
  ON_DA_FORM_ENABLE: 'ab.da.onFormEnable',
  ON_DA_PREFORM_HIDDEN: 'ab.da.onPreformHidden',
  //
  ON_REPEATER_FIELDS_INIT: 'ab.onRepeaterFieldsInit',
  ON_REPEATER_FIELDS_NEXT_ROW_INIT: 'ab.onRepeaterFieldsNextRowInit',
  ON_REPEATER_FIELDS_REMOVE_ROW: 'ab.onRepeaterFieldsRemoveRow',
  //
  ON_UPLOAD_REMOVED: 'ab.onUploadRemoved',
  //
  ON_DATEPICKER_TRIGGER_ACTIVATION: 'ab.onDatepickerTriggerActivation',
  ON_DATEPICKER_TRIGGER_DEACTIVATION: 'ab.onDatepickerTriggerDeactivation',
  //
  ON_SINGLE_SELECT_CHANGE: 'ab.onSingleSelectChange',
  // modal events
  ON_OPEN_LOGIN_MODAL: 'ab.onOpenLoginModal',
  ON_OPEN_ACTIVATION_MODAL: 'ab.onOpenActivationModal',
  ON_OPEN_EMAIL_UPDATE_MODAL: 'ab.onOpenEmailUpdateModal',

  // @TODO: Refactoring: könnte möglicherweise auch ohne pubSub laufen. Dazu Kommentar aus MR 2819 von Tobe:
  // Ist pubSub hier wirklich notwendig? Man könnte ja auch den js-Kram in die am höchstgelegenste script.js packen und mit eventListener-Delegation arbeiten. Geht auch in Vanilla-JS ziemlich gut.

  ON_OPEN_VACANCY_EDIT_MODAL: 'ab.onVacancyEditModal',
  ON_OPEN_VACANCY_NEW_MODAL: 'ab.onVacancyNewModal',
  ON_VACANCY_CREATED: 'ab.onVacancyCreated',

  ON_OPEN_BRANCH_NEW: 'ab.onOpenBranchNew',
  ON_OPEN_BRANCH_EDIT: 'ab.onOpenBranchEdit',
  ON_OPEN_BRANCH_SHOW: 'ab.onOpenBranchShow',
  ON_BRANCHES_RELOADED: 'ab.onBranchesReloaded',
  ON_BRANCH_CREATED: 'ab.onBranchCreated',
  ON_BRANCH_UPDATED: 'ab.onBranchUpdated',

  ON_OPEN_SUBSIDIARY_NEW: 'ab.onOpenSubsidiaryNew',
  ON_OPEN_SUBSIDIARY_EDIT: 'ab.onOpenSubsidiaryEdit',
  ON_OPEN_SUBSIDIARY_SHOW: 'ab.onOpenSubsidiaryShow',
  ON_SUBSIDIARIES_RELOADED: 'ab.onSubsidiariesReloaded',
  ON_SUBSIDIARY_CREATED: 'ab.onSubsidiaryCreated',
  ON_SUBSIDIARY_UPDATED: 'ab.onSubsidiaryUpdated',

  ON_OPEN_CONTACT_PERSON_NEW: 'ab.onOpenContactPersonNew',
  ON_OPEN_CONTACT_PERSON_EDIT: 'ab.onOpenContactPersonEdit',
  ON_CONTACT_PEOPLE_RELOADED: 'ab.onContactPeopleReloaded',
  ON_CONTACT_PERSON_CREATED: 'ab.onContactPersonCreated',
  ON_CONTACT_PERSON_UPDATED: 'ab.onContactPersonUpdated',

  ON_PROFESSION_REQUIREMENTS_CONFIG_CREATED: 'ab.onProfessionRequirementsConfigCreated',
  ON_PROFESSION_REQUIREMENTS_CONFIGS_RELOADED: 'ab.onProfessionRequirementsConfigsReloaded',

  ON_SHOW_SUCCESS_MESSAGE: 'ab.onShowSuccessMessage',
  ON_SHOW_ERROR_MESSAGE: 'ab.onShowErrorMessage',

  ON_CHANGE_FILTER_BOX: 'ab.onChangeFilterBox',

  ON_REDIRECT_REQUEST: 'ab.onRedirectRequest',

  ON_RELOAD_LOGIN_LINKS: 'ab.onReloadLoginLinks',

  ON_SCROLL_TOP: 'ab.onScrollTop',

  ON_MAIN_SEARCH_RESULTS_LOADED: 'ab.onMainSearchResutsLoaded',

  ON_JOB_POSTINGS_LOADED: 'ab.onJobPostingsLoaded',
  ON_SHOW_VACANCY: 'ab.onShowVacancy',

  ON_PASSWORD_RULES_FULLFILLED: 'ab.onPasswordRulesFullfilled',
  ON_PASSWORD_RULES_MISSED: 'ab.onPasswordRulesMissed',
}
