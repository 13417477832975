export function hideNavBackdrop() {
  const backdropElement = document.querySelector('div.mobile-nav-backdrop')

  if (document.body.contains(backdropElement)) {
    document.body.removeChild(backdropElement)
  }
}

export function showNavBackdrop() {
  const backdropElement = document.createElement('div')
  backdropElement.classList.add('mobile-nav-backdrop')

  document.body.appendChild(backdropElement)
}
