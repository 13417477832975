export default function transformURL(url) {
  const path = new URL(url).pathname

  const shortenedPath = path
    .replace(/\/unternehmen\//g, '/u/')
    .replace(/\/berufe\//g, '/b/')
    .replace(/\/stellen\//g, '/s/')

  const trimmedPath = shortenedPath.replace(/^\/|\/$/g, '')

  return trimmedPath
}
