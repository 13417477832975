/* global $ window */
import LayoutVariables from '../config/layout_variables'

export default class ViewportHelper {
  static get DESKTOP() {
    return 'desktop'
  }

  static get MOBILE() {
    return 'mobile'
  }

  static get TABLET() {
    return 'tablet'
  }

  /* DO NOT CALL DIRECTLY! PSEUDO SINGLETON! */
  constructor() {
    this.$window = $(window)
    this.lastViewport = this.getViewport()
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new ViewportHelper()
    }
    return this.instance
  }

  // returns the current viewport.
  getViewport() {
    const width = this.$window.width()
    let viewPort = ViewportHelper.DESKTOP

    if (width < LayoutVariables.tabletWidth) {
      viewPort = ViewportHelper.MOBILE
    } else if (width < LayoutVariables.desktopWidth) {
      viewPort = ViewportHelper.TABLET
    }

    return viewPort
  }

  isMobile() {
    return this.getViewport() === ViewportHelper.MOBILE
  }

  isTablet() {
    return this.getViewport() === ViewportHelper.TABLET
  }

  isDesktop() {
    return this.getViewport() === ViewportHelper.DESKTOP
  }

  getOrientation(input, { optionalInput, buffer = 25 }) {
    const $window = this.$window
    const inputTop = input.offset().top
    const inputBottom = input.offset().top + input.height()
    const scrollOffset = $window.scrollTop()
    const scrollBottom = scrollOffset + $window.height()
    const topDistance = Math.abs(scrollOffset - inputTop)
    const bottomDistance = Math.abs(scrollBottom - inputBottom)
    const optionalInputHeight =
      typeof optionalInput === 'undefined' ? null : optionalInput.height() + input.height() + buffer
    const optionalCondition = optionalInputHeight === null ? true : optionalInputHeight < inputTop

    let orientation = 'down'

    if (topDistance > bottomDistance && optionalCondition) {
      orientation = 'up'
    }

    return `orientation-${orientation}`
  }

  updateOrientation(container, input, optionalInput, buffer) {
    const orientationClass = this.getOrientation(input, { optionalInput, buffer })
    container.removeClass('orientation-up orientation-down')
    container.addClass(orientationClass)
    container.data('orientation', orientationClass)
  }

  // fires when the viewport is changed.
  // hands over current and last viewport.
  changeViewport(callback) {
    this.$window.on('resize', () => {
      const currentViewport = this.getViewport()
      if (this.lastViewport !== currentViewport) {
        callback(currentViewport, this.lastViewport)
        this.lastViewport = currentViewport
      }
    })
  }
}
