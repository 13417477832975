/* global window $ */

import dayjs from 'dayjs'
import { hideNavBackdrop } from '../../../javascript/src/lib/navigation_backdrop_helper'
import AjaxSpinnerWidget from '../../ajax_spinner/script'
import PasswordFieldWithValidationWidget from '../../password_field_with_validation/script'
import AbPubSub from '../../../javascript/src/lib/ab_pubsub'
import events from '../../../javascript/src/config/events'

const REGISTER_LINK = '.js-open_register_modal'
const LOGIN_MODAL = '.js-modal-login'
const MODAL = '.js-modal-register'
const AGE_SELECTOR = '.js-modal-age-field'
const PARENT_EMAIL_FIELD_SELECTOR = '.js-modal-parent-email-field'
const TOGGLE_PARENT_EMAIL_FIELD_CLASS = 'input-field__input--inactive'
const SELECTIZE_SELECTOR = '.js-modal-selectize'
const SUBMIT_BUTTON_SELECTOR = '.js-modal__submit--register'
const SIGN_UP_ROUTE = '/account/signup/'
const USER_MAIL_ICON_SELECTOR = '.user-at'
const PARENT_MAIL_ICON_SELECTOR = '.parent-at'
const USER_MAIL_INPUT_SELECTOR = '.js-modal__user_email'
const PARENT_MAIL_INPUT_SELECTOR = '.js-modal__parent_email'
const BIRTHDAY_CAKE_SELECTOR = '.js-cake'
const LOCK_ICON_SELECTOR = '.pw-lock'
const PASSWORD_SELECTOR = '.password-field'
const MODAL_FORM_STATE_CHANGE = 'ab.onModalRegisterFormStateChange'

if (!window.selectize) {
  window.selectize = require('selectize')
}

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default class ModalRegisterWidget {
  constructor() {
    this.pwf = new PasswordFieldWithValidationWidget(MODAL)

    this.validState = {
      [AGE_SELECTOR]: false,
      [USER_MAIL_INPUT_SELECTOR]: false,
      [PASSWORD_SELECTOR]: false,
    }

    this.pubSub = AbPubSub.getInstance()
    this.modal = $(MODAL)
    this.$namespace = this.modal.find('.register__content')

    this.$ageField = this.modal.find(AGE_SELECTOR)
    this.$submitButton = this.modal.find(SUBMIT_BUTTON_SELECTOR)

    this.initialState()
    this.initBehavior()
  }

  initialState() {
    this.toggleParentEmailField(true)
  }

  initBehavior() {
    this.registerUserMailInputListener()
    this.registerParentMailInputListener()
    this.registerPasswordFieldInputListener()

    this.registerAgeListener()
    this.registerModalControls()
    this.initSubmit()
  }

  initSubmit() {
    const ajaxSpinner = new AjaxSpinnerWidget()
    const $submitButton = this.modal.find(SUBMIT_BUTTON_SELECTOR)

    this.pubSub.subscribe(MODAL_FORM_STATE_CHANGE, ({ state }) => {
      const valid = Object.values(state).every((stateValue) => stateValue === true)

      if (valid) {
        this.pwf.enableSubmit()
      } else {
        this.pwf.disableSubmit()
      }
    })

    $submitButton.on('click', (e) => {
      e.preventDefault()
      this.clearErrors()

      ajaxSpinner.showSpinner()
      $submitButton.addClass('js-hidden')

      const redirectUrl = $('.js-job-application-pre-form').data('redirect-url')
      let url = `/ajax${SIGN_UP_ROUTE}`

      if (redirectUrl) {
        url = `${url}?redirect_url=${redirectUrl}`
      }

      $.ajax({
        url,
        method: 'POST',
        data: this.getSerializedFormData(),
      })
        .done(() => {
          // dont use cached document
          window.location.reload(true)
        })
        .fail((jqXHR) => {
          ajaxSpinner.hideSpinner()

          $submitButton
            .removeClass('js-hidden')
            .prop('disabled', false)
            .html('Jetzt kostenlos registrieren')
            .css('opacity', '1')
          // response is an object, so create an array from key-value pairs
          const errors = Object.entries(jqXHR.responseJSON.errors)
          this.addFieldError(errors)
        })
    })
  }

  registerModalControls() {
    $(REGISTER_LINK).on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()

      const dateString = $('.js-pre-date-of-birth').val()
      if (dateString) {
        const dateOfBirth = dayjs(dateString, 'DD.MM.YYYY')
        const age = dayjs().diff(dateOfBirth, 'years')

        this.$ageField.val(age)
        this.showOrHideU16Fields(age)
        $('.selectize-control.js-modal-age-field.js-modal-selectize').hide()

        if ($('.js-dynamic-age-field').length > 0) {
          $('.js-dynamic-age-field').remove()
        }

        this.$ageField
          .parent()
          .append(
            `<input name="signup[age]" class="input-field__input modal__age js-dynamic-age-field" readonly=true type=text value="${age}">`
          )

        this.updateState(AGE_SELECTOR, this.$ageField.val() !== '')
      }

      $(LOGIN_MODAL).hide()
      this.modal.css('display', 'flex')
    })

    this.modal.find('.js-register-modal-cross').on('click', (e) => {
      e.preventDefault()
      hideNavBackdrop()
      this.modal.hide()
    })
  }

  addFieldError(errors) {
    errors.forEach((error) => {
      const inputField = this.$namespace.find('form').find(`.modal__${error[0]}`)

      inputField.on('keypress', this.removeError)
      $(inputField.parent()).addClass('field_with_errors')

      // this breaks on password field. password input-element is wrapped insided another div.
      // also, the password live-validation is in the same location
      this.addErrorMessage(inputField.parent(), error[1])
    })
  }

  removeError() {
    $(this).off('keypress')
    const parentElement = $(this).parent()
    parentElement.removeClass('field_with_errors').find('span').remove()
  }

  addErrorMessage(element, messages) {
    messages.forEach((message) => {
      element.append(`<span class="form-error-message">${message}</span>`)
    })
  }

  clearErrors() {
    const errorInputElements = this.$namespace.find('form').find('.field_with_errors')
    errorInputElements.removeClass('field_with_errors').find('.form-error-message').remove()
  }

  getSerializedFormData() {
    return this.$namespace.find('form').serialize()
  }

  registerAgeListener() {
    const $selectize = this.modal.find(SELECTIZE_SELECTOR)
    $selectize.selectize()

    const $birthdayCakeIcon = this.modal.find(BIRTHDAY_CAKE_SELECTOR)

    this.$ageField.on('change', () => {
      $birthdayCakeIcon.toggleClass('filled', $selectize.siblings().find('.full'))

      this.$ageField.parent().removeClass('field_with_errors').find('span').remove()
      this.showOrHideU16Fields(this.$ageField.val())
      this.updateState(AGE_SELECTOR, this.$ageField.val() !== '')
    })
  }

  showOrHideU16Fields(age = '') {
    if (age === '' || parseInt(age, 10) >= 16) {
      this.toggleParentEmailField(true)
    } else {
      this.toggleParentEmailField(false)
    }
  }

  toggleParentEmailField(addOrRemove) {
    this.modal.find(PARENT_EMAIL_FIELD_SELECTOR).toggleClass(TOGGLE_PARENT_EMAIL_FIELD_CLASS, addOrRemove)
    this.toggleParentalEmailState(!addOrRemove)
  }

  registerUserMailInputListener() {
    const $field = this.modal.find(USER_MAIL_INPUT_SELECTOR)
    const $icon = this.modal.find(USER_MAIL_ICON_SELECTOR)

    this.toggleIconClass($field, $icon, (condition) => {
      this.updateState(USER_MAIL_INPUT_SELECTOR, condition)
    })
  }

  registerParentMailInputListener() {
    const $field = this.modal.find(PARENT_MAIL_INPUT_SELECTOR)
    const $icon = this.modal.find(PARENT_MAIL_ICON_SELECTOR)

    this.toggleIconClass($field, $icon, (condition) => {
      this.updateState(PARENT_EMAIL_FIELD_SELECTOR, condition)
    })
  }

  registerPasswordFieldInputListener() {
    const $field = this.pwf.getPassswordField()
    const $icon = this.modal.find(LOCK_ICON_SELECTOR)

    this.toggleIconClass($field, $icon)

    this.pubSub.subscribe(events.ON_PASSWORD_RULES_FULLFILLED, ({ namespace }) => {
      if (namespace === MODAL) {
        this.updateState(PASSWORD_SELECTOR, true)
      }
    })

    this.pubSub.subscribe(events.ON_PASSWORD_RULES_MISSED, ({ namespace }) => {
      if (namespace === MODAL) {
        this.pwf.disableSubmit()
        this.updateState(PASSWORD_SELECTOR, false)
      }
    })
  }

  toggleIconClass($field, $icon, callback = null) {
    $field.on('input', () => {
      const condition = $field.val() !== ''
      $icon.toggleClass('filled', condition)

      if (typeof callback === 'function') {
        callback(condition)
      }
    })
  }

  toggleParentalEmailState(addOrRemove) {
    if (addOrRemove) {
      this.validState[PARENT_EMAIL_FIELD_SELECTOR] = false
    } else {
      delete this.validState[PARENT_EMAIL_FIELD_SELECTOR]
    }
  }

  updateState(key, value) {
    this.validState[key] = value
    this.pubSub.emit(MODAL_FORM_STATE_CHANGE, { state: this.validState })
  }
}
