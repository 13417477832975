/* global window document */

import Events from '../config/events'
import UtilityHelper from './utility_helper'

export default class GlobalWindowEventManager {
  constructor(pubSub) {
    this.pubSub = pubSub

    this.registerEvents()
  }

  registerEvents() {
    window.addEventListener('resize', e => {
      this.pubSub.emit(Events.ON_AB_RESIZE, e)
    })

    window.addEventListener(
      'scroll',
      e => {
        this.pubSub.emit(Events.ON_AB_SCROLL, e)
      },
      { passive: true }
    )
  }
}
