/* global $ */
import Events from '../../../../javascript/src/config/events'

export default class NavigationSearchBar {
  get searchNamespace() {
    return '.js-navigation-search-bar'
  }

  get openedSearchBarClass() {
    return 'js-navigation-search-bar--open'
  }

  get navigationToggle() {
    return '.js-toggle-navigation'
  }

  get inputPrefix() {
    return '.navigation-search-bar__input'
  }

  constructor() {
    this.$ns = $(this.searchNamespace)
    this.$whatField = this.$ns.find(`${this.inputPrefix}--what`)
    this.$navigationToggle = $(this.navigationToggle)

    this.initTrackingFields()
    this.initBehavior()
  }

  initTrackingFields() {
    const whatField = this.$whatField
    const whereField = this.$ns.find(`${this.inputPrefix}--where`)
    const whatTrackingField = $('#t_nav_search_bar_what')
    const whereTrackingField = $('#t_nav_search_bar_where')

    whatField.on('change', () => {
      this.copyValueToField(whatTrackingField, whatField.val())
    })

    whereField.on('change', () => {
      this.copyValueToField(whereTrackingField, whereField.val())
    })

    this.copyValueToField(whatTrackingField, whatField.val())
    this.copyValueToField(whereTrackingField, whereField.val())
  }

  copyValueToField(field, value) {
    field.val(value)
  }

  hideSearch() {
    this.$ns.removeClass(this.openedSearchBarClass)
    this.$ns.trigger(Events.ON_NAVIGATION_SEARCH_CLOSE)
  }

  showSearch() {
    this.$ns.addClass(this.openedSearchBarClass)
    this.$ns.trigger(Events.ON_NAVIGATION_SEARCH_OPEN)
    this.$whatField.trigger('focus')
  }

  initBehavior() {
    this.$ns.find('.js-toggle-search-button').on('click', () => {
      if (this.$ns.hasClass(this.openedSearchBarClass)) {
        this.hideSearch()
      } else {
        this.showSearch()
      }
    })
  }
}
