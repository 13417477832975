/* global $ window */

import cookie from 'js-cookie'
import AbPubSub from '../../../javascript/src/lib/ab_pubsub'
import Events from '../../../javascript/src/config/events'
import { hideNavBackdrop } from '../../../javascript/src/lib/navigation_backdrop_helper'
import PasswordFieldWidget from '../../password_field/script'

const SIGN_IN_ROUTE = 'account/signin/'
const USER_MAIL_INPUT_SELECTOR = '.js-modal__email'
const USER_MAIL_ICON_SELECTOR = '.login-at'
const MODAL_SELECTOR = '.js-modal-login'
const SUBMIT_BUTTON_SELECTOR = '.js-modal__submit--login'
const REGISTER_MODAL_SELECTOR = '.js-modal-register'
const LOGIN_LINK_SELECTOR = '.js-open_login_modal'

export default class ModalLoginWidget {
  constructor() {
    const $modal = $(MODAL_SELECTOR)
    const $registerModal = $(REGISTER_MODAL_SELECTOR)
    const $submitButton = $(SUBMIT_BUTTON_SELECTOR)
    this.$apprenticeLoginLink = $(LOGIN_LINK_SELECTOR)

    this.$userLoginIcon = $(USER_MAIL_ICON_SELECTOR)
    this.$userMailInput = $(USER_MAIL_INPUT_SELECTOR)

    this.passwordField = new PasswordFieldWidget(MODAL_SELECTOR)

    this.$modal = $modal
    this.$registerModal = $registerModal
    this.$namespace = $('.modal__content')
    let popUp = ''

    const abPubSub = AbPubSub.getInstance()

    abPubSub.subscribe(Events.ON_OPEN_LOGIN_MODAL, () => {
      $registerModal.hide()
      $modal.css('display', 'flex')
    })

    abPubSub.subscribe(Events.ON_RELOAD_LOGIN_LINKS, () => {
      // needs to be the selector and not the instance variable
      // since elements with the class are dynamically loaded
      $(LOGIN_LINK_SELECTOR).on('click', (e) => {
        e.preventDefault()
        e.stopPropagation()
        e.stopImmediatePropagation()

        const $clicked = $(e.currentTarget).data('triggerPopup')

        // used for triggering content after login like follow card, author-form-box
        if ($clicked) {
          popUp = $clicked
        } else {
          popUp = ''
        }

        $registerModal.hide()
        $modal.css('display', 'flex')

        this.pushEventIfHasClass(e.currentTarget, 'corporation-follow-cta', 'company_subscription_show_login')
        this.pushEventIfHasClass(e.currentTarget, 'jobletter-button', 'search_subscription_show_login')
      })
    })

    this.$apprenticeLoginLink.on('click', (e) => {
      e.preventDefault()
      e.stopPropagation()

      const $clicked = $(e.currentTarget).data('triggerPopup')

      // used for triggering content after login like follow card, author-form-box
      if ($clicked) {
        popUp = $clicked
      } else {
        popUp = ''
      }

      $registerModal.hide()
      $modal.css('display', 'flex')
    })

    $modal.find('.js-login-modal-cross').on('click', (e) => {
      e.preventDefault()
      hideNavBackdrop()
      $modal.hide()
    })

    $submitButton.on('click', (e) => {
      e.preventDefault()
      this.clearErrors()

      $.ajax({
        url: `/ajax/${SIGN_IN_ROUTE}`,
        method: 'POST',
        data: this.getSerializedFormData(),
      })
        .done((xhr) => {
          if (popUp) {
            cookie.set('triggerPopUp', popUp, { expires: 1 })
          }
          // dont use cached document
          window.location = xhr.redirect
        })
        .fail((jqXHR) => {
          // response is an object, so create an array from key-value pairs
          this.addFieldError(jqXHR.responseJSON.errors)
        })
    })
    this.onUserMailInputListener()
    this.automaticallyOpenLoginModal()
  }

  addFieldError(errors) {
    $.each(errors, (key, value) => {
      const $inputField = this.$namespace.find('form').find(`.modal__${key}`)

      if (key !== 'general') {
        $inputField.on('keypress', this.removeError)
      }

      let parentElement = $inputField.parent()
      if (key === 'password') {
        parentElement = parentElement.parent()
      }

      parentElement.addClass('field_with_errors')
      this.addErrorMessage(parentElement, value)
    })
  }

  removeError() {
    const $input = $(this)
    $input.off('keypress')
    let parentElement = $input.parent()

    if ($input.hasClass('modal__password')) {
      parentElement = parentElement.parent()
    }

    parentElement.removeClass('field_with_errors').find('span').remove()
  }

  addErrorMessage(element, messages) {
    messages.forEach((message) => {
      element.append(`<div class="form-error-message">${message}</div>`)
    })
  }

  clearErrors() {
    const errorInputElements = this.$namespace.find('form').find('.field_with_errors')
    errorInputElements.removeClass('field_with_errors').find('.form-error-message').remove()
  }

  getSerializedFormData() {
    return this.$namespace.find('form').serialize()
  }

  onUserMailInputListener() {
    this.$userMailInput.on('input', () => {
      this.$userLoginIcon.toggleClass('filled', this.$userMailInput[0].value.length > 0)
    })
  }

  automaticallyOpenLoginModal() {
    if (!!this.$namespace[0] && this.$namespace[0].dataset.jsOpenLoginModal === '1') {
      this.$apprenticeLoginLink[0].click()
    }
  }

  pushEventIfHasClass(target, className, eventName) {
    if ($(target).hasClass(className)) {
      window.dataLayer.push({
        event: eventName,
      })
    }
  }
}
