export default class FormattedPhoneNumberInput {
  constructor(selector = '.js-phone-number') {
    this.selector = selector
    this.$instances = $(this.selector)

    if (this.$instances.length) {
      this.init()
    }
  }

  init() {
    this.$instances.each((idx, elem) => {
      $(elem).on('keypress', e => {
        let key = e.key
        let cursorPosition = e.target.selectionStart
        let phoneNumber = e.target.value
        let firstChar = phoneNumber[0]
        let allowedChars = '+-/( )0123456789'
        let allowedFirstChars = '+(0123456789'

        if (!allowedChars.includes(key)) {
          e.preventDefault()
        } else if (firstChar == '+' && cursorPosition == 0) {
          e.preventDefault()
        } else if (key == '+' && (phoneNumber.includes('+') || cursorPosition !== 0)) {
          e.preventDefault()
        } else if (cursorPosition == 0 && !allowedFirstChars.includes(key)) {
          e.preventDefault()
        }
      })
    })
  }
}
