/* global $ window */

import Events from '../../../javascript/src/config/events'
import AbPubSub from '../../../javascript/src/lib/ab_pubsub'

const EMAIL_UPDATE_MODAL = '.js-email-update-modal'
const SHOW_MODAL_JS_CLICK = '.js-open_email_update_modal'
const CLOSE_MODAL_BUTTON = '.js-email-update-modal-cross'
const REFRESH_TAG = '.js-email-update-modal-refresh'
const AZUBI_COCKPIT_PATH = '/dashboard/azubi/einstellungen'

export default class EmailUpdateWidget {
  constructor() {
    this.$emailUpdateModal = $(EMAIL_UPDATE_MODAL)
    this.$closeModalButton = $(CLOSE_MODAL_BUTTON)
    this.$modalTriggerLinks = $(SHOW_MODAL_JS_CLICK)
    this.$refreshTag = $(REFRESH_TAG)

    const abPubSub = AbPubSub.getInstance()

    abPubSub.subscribe(Events.ON_OPEN_EMAIL_UPDATE_MODAL, () => {
      e.preventDefault()
      // stopping the propagation is neccessary here
      e.stopPropagation()
      this.$emailUpdateModal.css({ display: 'flex' })
    })

    this.$modalTriggerLinks.on('click', e => {
      e.preventDefault()
      // stopping the propagation is neccessary here
      e.stopPropagation()

      $('#azubi-cockpit-button').html(
        `<a target="_blank" class="btn-filled" href=${AZUBI_COCKPIT_PATH}>Zum Azubi-Cockpit</a>`
      )

      this.$emailUpdateModal.css({ display: 'flex' })
    })

    this.addEvents()
  }

  addEvents() {
    this.$closeModalButton.on('click', () => {
      this.$emailUpdateModal.hide()
    })

    this.$refreshTag.on('click', () => {
      window.location.reload()
    })
  }
}
