/* global window $ */

export default class AdminBar {
  static get validChunks() {
    return ['unternehmen', 'hochschulen', 'stellen']
  }

  static get adminClass() {
    return 'is-admin'
  }

  constructor() {
    if (!$('body').hasClass(AdminBar.adminClass)) {
      return
    }

    this.pathname = window.location.pathname
    this.match()
  }

  match() {
    const chunks = this.pathname.split('/')
    let index = false // every number except 0 is truthy

    if (chunks.indexOf('dashboard') > -1) {
      return
    }

    chunks.forEach((chunk, idx) => {
      if (AdminBar.validChunks.indexOf(chunk) > -1) {
        if (!index) {
          index = idx
        }
      }
    })

    if (!index) {
      return
    }

    const slug = chunks[index + 1]
    this.renderLink(slug)
  }

  renderLink(slug) {
    $.ajax({
      method: 'GET',
      url: '/ajax/admin_bar',
      data: {
        slug
      }
    }).done(response => {
      if (response.payload) {
        const payload = response.payload
        const impersonation = payload.impersonation
        const corporationAdmin = payload.corporation_admin

        const $impersonationHref = $('<a />', {
          html: `<small><i class="icon-user"></i> Impersonate:</small> <b>${impersonation.name}</b>`,
          href: impersonation.link,
          class: 'admin-bar-trigger',
          title: `Als "${impersonation.name}" ansehen`
        })

        const $corpAdminHref = $('<a />', {
          html: '<small><i class="icon-industry"></i></small> Im Adminpanel',
          href: corporationAdmin.link,
          title: `Unternehmen "${impersonation.name}" im Adminpanel ansehen`
        })

        $('.admin-bar__impersonation').append($impersonationHref)
        $('.admin-bar__panel').append($corpAdminHref)
      }
    })
  }
}
