import AbPubSub from '../../../javascript/src/lib/ab_pubsub'
import Events from '../../../javascript/src/config/events'

/* global $ window */
const SHOW_MODAL_TRIGGER = '.js-show-activation-modal'
const SHOW_MODAL_JS_CLICK = '.js-open_activation_modal'
const MODAL_ACTIONS = '.js-account-activation-modal-actions'
const EMAILS_RESEND_BUTTON = '.js-account-activation-modal-resend-mail-button'
const MODAL_TEXT_CONTAINER = '.js-account-activation-modal-text'
const WARNING_TRIGGER_BUTTON = '.js-account-activation-warning-container'
const ACTIVATION_MODAL = '.js-account-activation-modal'
const CLOSE_MODAL_BUTTON = '.js-account-activation-modal-cross'
const SEND_EMAILS_URL = '/apprentice_send_confirmation_emails/'
const MODAL_CONTENT_URL = '/account/new/'

export default class AccountActivationWidget {
  constructor() {
    this.$sendEmailsButton = $(EMAILS_RESEND_BUTTON)
    this.$modalActions = $(MODAL_ACTIONS)
    this.$modalTextContainer = $(MODAL_TEXT_CONTAINER)
    this.$modalTrigger = $(SHOW_MODAL_TRIGGER)
    this.$warningButton = $(WARNING_TRIGGER_BUTTON)
    this.$activationModal = $(ACTIVATION_MODAL)
    this.$closeModalButton = $(CLOSE_MODAL_BUTTON)
    this.$modalTriggerLinks = $(SHOW_MODAL_JS_CLICK)

    const abPubSub = AbPubSub.getInstance()

    abPubSub.subscribe(Events.ON_OPEN_ACTIVATION_MODAL, () => {
      this.$activationModal.css({ display: 'flex' })
      this.addEvents()
    })

    this.$modalTriggerLinks.on('click', e => {
      e.preventDefault()
      e.stopPropagation()
      this.updateModalText()
      this.$activationModal.css({ display: 'flex' })
    })

    if (this.$modalActions.length) {
      this.updateModalText()
      this.triggerModal()
      this.addEvents()
    }
  }

  triggerModal() {
    if (this.$modalTrigger.length) {
      this.$activationModal.css({ display: 'flex' })

      window.dataLayer.push({ event: 'registration' })
    }
  }

  addEvents() {
    this.$sendEmailsButton.on('click', () => {
      this.resendEmails()
    })

    this.$warningButton.on('click', () => {
      this.$activationModal.css({ display: 'flex' })
      this.updateModalText()
    })

    this.$closeModalButton.on('click', () => {
      this.$activationModal.hide()
    })
  }

  updateModalText() {
    this.call(MODAL_CONTENT_URL, data => {
      if (data) {
        this.$modalTextContainer.html(data.text)
        const resendMailButtonText = data.resend_mail_button_text
        const resendMailButtonTextOpposit = data.resend_mail_button_text_opposit
        if (resendMailButtonText) {
          this.$sendEmailsButton.data('opposit', resendMailButtonTextOpposit)
          this.$sendEmailsButton.html(resendMailButtonText)
        } else {
          this.$modalActions.hide()
        }
      } else {
        this.$activationModal.hide()
      }
    })
  }

  resendEmails() {
    this.call(SEND_EMAILS_URL, data => {
      if (data.msg === 'ok') {
        this.$sendEmailsButton
          .html(this.$sendEmailsButton.data('opposit'))
          .removeClass('active')
          .off()
      }
    })
  }

  call(route, successCallback) {
    $.ajax({
      url: `//${window.location.host}/ajax${route}`,
      type: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.csrfToken
      },
      success: successCallback
    })
  }
}
