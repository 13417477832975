/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import jQuery from 'jquery'

window.jQuery = jQuery
window.$ = jQuery
const $ = jQuery

// add jquery function for resetting selectize selects
$.fn.resetSelectize = function () {
  // Get the Selectize instance
  const { selectize } = this[0]

  // get initial options
  const options = $.map(selectize.options, (value) => value)

  // clear all options
  selectize.clearOptions()
  // reinit initial options
  selectize.load(function (callback) {
    callback(options)
  })

  // reset width
  $($(this).siblings()[0]).find('input').css('width', '100%')

  // Return the jQuery object for chaining
  return this
}

export default $
