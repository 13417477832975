/* global $,history */
import AbPubSub from './ab_pubsub'
import AbstractScrollTo from './abstract_scrollto'
import EVENTS from '../config/events'

const ERROR_MESSAGE = '.form-error-message'
const TOP = 0

export default class ScrollTo extends AbstractScrollTo {
  constructor() {
    super()
    this.pubSub = AbPubSub.getInstance()
    this.initScrollToErrorMessage(ERROR_MESSAGE, 50)
    this.initScrollToTop(TOP, 50)
  }

  initScrollToTop(elem, speed) {
    $('.js-scroll-top').on('click', () => {
      this.pubSub.emit(EVENTS.ON_SCROLL_TOP)
      super.scrollTo(elem, speed)
    })
  }

  initScrollToErrorMessage(elem, speed) {
    if ($(elem).length) {
      super.scrollTo($(elem).offset().top - 150, speed)
    }
  }
}
