/* global $ */

const SPINNER_SELECTOR = '.js-ajax-spinner'

export default class AjaxSpinnerWidget {
  constructor(spinnerSelector = SPINNER_SELECTOR) {
    this.spinnerSelector = spinnerSelector
  }

  showSpinner() {
    $(this.spinnerSelector).removeClass('ajax-spinner--inactive')
  }

  hideSpinner() {
    $(this.spinnerSelector).addClass('ajax-spinner--inactive')
  }
}
