import dayjs from 'dayjs'

const customParseFormat = require('dayjs/plugin/customParseFormat')

dayjs.extend(customParseFormat)

export default class UtilityHelper {
  /**
   * FIX: needs fixing through new URLSearchParams(window.location.search)
   * we don't need to do the heavy lifting here!
   * @param {} url
   * @returns
   */
  static getUrlParams(url) {
    const params = {}
    const parser = document.createElement('a')
    parser.href = url

    const query = parser.search.substring(1)
    if (query !== '') {
      const vars = query.split('&')
      for (let i = 0; i < vars.length; i += 1) {
        const unsafeChars = ['%', '%25', '%7B', '%7D']
        const pair = vars[i].split('=')

        if (unsafeChars.includes(pair[1])) {
          params[decodeURIComponent(pair[0])] = ''
        } else {
          params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
        }
      }
      return params
    }

    return {}
  }

  static parseDate(dateString, formatString) {
    return dayjs(dateString, formatString).toDate()
  }

  static stringToBoolean(string) {
    const truthy = ['1', 'true']
    return truthy.includes(string)
  }

  static findAncestor(elem, selector) {
    while (!(elem.matches || elem.matchesSelector).call(elem, selector)) {
      // eslint-disable-next-line no-param-reassign
      elem = elem.parentElement
    }

    return elem
  }

  static debounce(func, wait, immediate) {
    let timeout

    return function retDebounce(...args) {
      const context = this

      const later = function laterDebounce() {
        timeout = null
        if (!immediate) func.apply(context, args)
      }

      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }
}
