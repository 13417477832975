export default class AbPubSub {
  /* DO NOT CALL DIRECTLY! PSEUDO SINGLETON! */
  constructor() {
    this.events = {}
  }

  /* ALWAYS USE THIS! */
  static getInstance() {
    if (!this.instance) {
      this.instance = new AbPubSub()
    }
    return this.instance
  }

  subscribe(eventName, callback) {
    this.events[eventName] = this.events[eventName] || []
    this.events[eventName].push(callback)
  }

  unsubscribe(eventName, callback) {
    if (this.events[eventName]) {
      for (let i = 0, len = this.events[eventName].length; i < len; i++) {
        if (this.events[eventName][i] === callback) {
          this.events[eventName].splice(i, 1)
          break
        }
      }
    }
  }

  emit(eventName, data) {
    if (this.events[eventName]) {
      this.events[eventName].forEach(callback => {
        callback(data)
      })
    }
  }
}
