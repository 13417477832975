/* global $ */

const MIRROR_NS = '.js-mirror'

export default class MirrorSearch {
  constructor() {
    this.$mirros = $(MIRROR_NS)
    this.initMirror()
    this.triggerListener()
  }

  initMirror() {
    const events = 'change keypress keyup blur'

    this.$mirros.each((index, mirror) => {
      const $mirror = $(mirror)
      const $target = $($mirror.data('mirrorTarget'))

      $target.on(events, () => {
        $mirror.val($target.val())
      })
    })
  }

  triggerListener() {
    this.$mirros.on('ab.onJsMirrorTrigger', event => {
      const $mirror = $(event.target)
      const $target = $($mirror.data('mirrorTarget'))
      $mirror.val($target.val())
    })
  }
}
