// custom libs
import AbLoadHTMLTemplate from './src/lib/html_load_template'

/* global window */
import Root from './src/pages/frontend/root'

import 'jquery-ujs'
import 'slick-carousel'
import $ from './jquery.js'

new AbLoadHTMLTemplate()

class Ausbildung {
  constructor() {
    new Root()
  }
}

$(() => {
  window.ausbildung = new Ausbildung()
})
