/* global $ */

export default class removeErrors {
  constructor() {
    $.each($('.field_with_errors'), (index, value) => {
      // Selects with selectize do not trigger input events. For this case we listen to change events
      $(value.firstChild).on('input change', () => {
        $(value).removeClass('field_with_errors')
      })
    })
  }
}
