/* global $ */
const MODAL = '.js-modal'
const MODAL_CROSS = '.js-cross'

export default class ModalWidget {
  constructor() {
    this.$modal = $(MODAL)
    this.$modalCross = $(MODAL_CROSS)

    this.addCrossEvent()
  }

  addCrossEvent() {
    this.$modalCross.on('click', () => {
      this.closeModal()
    })
  }

  closeModal() {
    this.$modal.hide()
  }
}
