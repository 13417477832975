/* global $ */

export default class HTMLTemplateLoader {
  constructor() {
    $.fn.extend({
      /**
       * loads a template defined as <script type="text/template" /> and returns
       * the contents as a jQuery Object
       *
       * return $
       */
      abLoadHTMLTemplate: function abLoadHTMLTemplate() {
        // scope change to jQuery!!!
        return $($(this[0]).clone().html())
      }
    })
  }
}
