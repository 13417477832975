/* global $ */

export default class AbstractSearch {
  get searchNamespace() {
    return '.js-main-search'
  }

  get whatField() {
    return '.js-search-what'
  }

  get whereField() {
    return '.js-search-where'
  }

  get clearInput() {
    return '.js-clear-input'
  }

  constructor() {
    this.$searchNamespace = $(this.searchNamespace)
    this.$whatFields = this.$searchNamespace.find(this.whatField)
    this.$whereFields = this.$searchNamespace.find(this.whereField)
    this.$clearInputs = this.$searchNamespace.find(this.clearInput)

    this.initInputs()
  }

  initInputs() {
    this.$clearInputs.each((_, clearButton) => {
      const $clearButton = $(clearButton)
      const $correspondingInput = $clearButton.siblings('input')[0]

      if ($correspondingInput.value.length >= 1) {
        $clearButton.removeClass('clear-input--hidden')
      } else {
        $clearButton.addClass('clear-input--hidden')
      }
    })

    this.handleInput()
    this.handleInputClear()
  }

  handleInput() {
    const $allInputs = $.merge(this.$whatFields, this.$whereFields)

    $allInputs.each((_, input) => {
      const $input = $(input)
      const $correspondingClearButton = $input.siblings(this.clearInput)[0]

      $input.on('input', () => {
        if ($input.val().length >= 1) {
          $correspondingClearButton.classList.remove('clear-input--hidden')
        } else {
          $correspondingClearButton.classList.add('clear-input--hidden')
        }
      })
    })
  }

  handleInputClear() {
    this.$clearInputs.each((_, element) => {
      const $element = $(element)
      const $correspondingInput = $element.siblings('input')[0]

      $element.on('click', () => {
        $correspondingInput.value = ''
        $element.addClass('clear-input--hidden')
      })
    })
  }
}
