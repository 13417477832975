/* global $ */

const PROFESSION_CAROUSEL_SELECTOR = '.js-profession-carousel'

export default class ProfessionCarouselWidget {
  constructor() {
    $(PROFESSION_CAROUSEL_SELECTOR).slick({
      lazyLoad: 'progressive',
      slidesToShow: 3,
      variableWidth: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            arrows: false,
            slidesToShow: 3,
            centerMode: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            arrows: false,
          },
        },
      ],
    })

    $(PROFESSION_CAROUSEL_SELECTOR).on('beforeChange', (_event, slick, currentSlide, nextSlide) => {
      const maxSlideIndex = slick.slideCount - 1

      if (currentSlide === maxSlideIndex && nextSlide === 0) {
        $('.slick-slide[data-slick-index=0]').removeClass('slick-cloned-active')
        $(`.slick-slide[data-slick-index=${maxSlideIndex + 1}]`).addClass('slick-cloned-active slick-cloned-center')
        $('.slick-slide[data-slick-index=-1]').removeClass('slick-cloned-active')
      } else if (currentSlide === 0 && nextSlide === 1) {
        $(`.slick-slide[data-slick-index=${maxSlideIndex + 1}]`).removeClass('slick-cloned-active slick-cloned-center')
      } else if (currentSlide === 0 && nextSlide === maxSlideIndex) {
        $('.slick-slide[data-slick-index=0]').addClass('slick-cloned-active')
        $('.slick-slide[data-slick-index=-1]').addClass('slick-cloned-active')
        $(`.slick-slide[data-slick-index=${maxSlideIndex + 1}]`).removeClass('slick-cloned-active slick-cloned-center')
      } else if (currentSlide === maxSlideIndex && nextSlide === maxSlideIndex - 1) {
        $('.slick-slide[data-slick-index=0]').removeClass('slick-cloned-active')
        $('.slick-slide[data-slick-index=-1]').removeClass('slick-cloned-active')
      }
    })
  }
}
