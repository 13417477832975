/* global $ */

import PasswordFieldWidget from '../password_field/script'
import AbPubSub from '../../javascript/src/lib/ab_pubsub'
import events from '../../javascript/src/config/events'

const PASSWORD_VALIDATION_INFO_FIELD = '.password-validation__info'
const PASSWORD_VALIDATION_PROGRESSBAR = '.password-validation__progressbar'
const FIRST_STAGE = '.first-stage'
const SECOND_STAGE = '.second-stage'
const THIRD_STAGE = '.third-stage'
const SUBMIT_BUTTON = '.js-submit-btn' // Insert this class to your form submit-button to get this fancy validation

const PROGRESS_COLOR_GREY = '#D4D4D4'
const PROGRESS_COLOR_RED = '#C33'
const PROGRESS_COLOR_YELLOW = '#F80'
const PROGRESS_COLOR_GREEN = '#3C3'

export default class PasswordFieldWithValidationWidget {
  constructor(namespace = null) {
    this.initPasswordField(namespace)
    this.initStages(namespace)
    this.initSubmit(namespace)
    this.pubSub = AbPubSub.getInstance()

    this.digitInfo = ''
    this.letterInfo = ''
    this.letterCountInfo = ''
    this.password = ''
    this.minPasswordLength = 8
    this.state = 0

    // this.hideProgressbar()
    this.disableSubmit()
    this.formFieldFocus()
    this.passwordObserver(namespace)
  }

  initSubmit(namespace) {
    this.$submitButton = $(namespace ? `${namespace} ${SUBMIT_BUTTON}` : SUBMIT_BUTTON)
  }

  initStages(namespace) {
    this.$firstStage = $(namespace ? `${namespace} ${FIRST_STAGE}` : FIRST_STAGE)
    this.$secondStage = $(namespace ? `${namespace} ${SECOND_STAGE}` : SECOND_STAGE)
    this.$thridStage = $(namespace ? `${namespace} ${THIRD_STAGE}` : THIRD_STAGE)
  }

  initPasswordField(namespace) {
    const passwordField = new PasswordFieldWidget(namespace)
    this.$formElement = passwordField.getPasswordFormElement()
    this.$passwordField = passwordField.getPasswordField()

    let infoFieldSelector = PASSWORD_VALIDATION_INFO_FIELD
    let progressBarSelector = PASSWORD_VALIDATION_PROGRESSBAR
    if (namespace) {
      infoFieldSelector = `${namespace} ${infoFieldSelector}`
      progressBarSelector = `${namespace} ${progressBarSelector}`
    }

    this.$passwordValidationInfoField = $(infoFieldSelector)
    this.$passwordValidationProgressbar = $(progressBarSelector)
  }

  getPassswordField() {
    return this.$passwordField
  }

  passwordObserver(namespace) {
    this.$formElement
      .on('keyup', (e) => {
        this.password = $(e.currentTarget).val()
        this.state = this.calcState(this.password)

        this.updateInfoText()
        this.updateProgressbar()
      })
      .on('blur', () => {
        this.submitButtonAccess(namespace)
      })
  }

  submitButtonAccess(namespace) {
    const emitEvent = this.isValid(this.password)
      ? events.ON_PASSWORD_RULES_FULLFILLED
      : events.ON_PASSWORD_RULES_MISSED

    this.pubSub.emit(emitEvent, { namespace })
  }

  formFieldFocus() {
    this.$formElement.on('focus', () => {
      this.updateInfoText()
    })
  }

  updateInfoText() {
    if (this.hasDigit(this.password)) {
      this.digitInfo = ''
    } else {
      this.digitInfo = ' Noch mind. 1 Ziffer.'
    }

    if (this.hasLetter(this.password)) {
      this.letterInfo = ''
    } else {
      this.letterInfo = ' Noch mind. 1 Buchstabe.'
    }
    if (this.passwordLength(this.password) >= this.minPasswordLength) {
      this.letterCountInfo = ''
    } else if (this.passwordLength(this.password) > this.minPasswordLength - 2) {
      this.letterCountInfo = `Noch mind. ${this.minPasswordLength - this.passwordLength(this.password)} weiteres Zeichen. `
    } else {
      this.letterCountInfo = `Noch mind. ${this.minPasswordLength - this.passwordLength(this.password)} weitere Zeichen. `
    }

    if (this.isValid(this.password)) {
      this.$passwordValidationInfoField.text('Starkes Passwort!')
    } else {
      this.$passwordValidationInfoField.text(this.letterCountInfo + this.letterInfo + this.digitInfo)
    }
  }

  updateProgressbar() {
    if (this.state === 1) {
      this.$firstStage.css('background', PROGRESS_COLOR_RED)
      this.$secondStage.css('background', PROGRESS_COLOR_GREY)
      this.$thridStage.css('background', PROGRESS_COLOR_GREY)
    } else if (this.state === 2) {
      this.$firstStage.css('background', PROGRESS_COLOR_YELLOW)
      this.$secondStage.css('background', PROGRESS_COLOR_YELLOW)
      this.$thridStage.css('background', PROGRESS_COLOR_GREY)
    } else if (this.state === 3) {
      this.$firstStage.css('background', PROGRESS_COLOR_GREEN)
      this.$secondStage.css('background', PROGRESS_COLOR_GREEN)
      this.$thridStage.css('background', PROGRESS_COLOR_GREEN)
    } else {
      this.$firstStage.css('background', PROGRESS_COLOR_GREY)
      this.$secondStage.css('background', PROGRESS_COLOR_GREY)
      this.$thridStage.css('background', PROGRESS_COLOR_GREY)
    }
  }

  calcState() {
    let state = 0

    if (this.hasDigit(this.password)) {
      state += 1
    }
    if (this.hasLetter(this.password)) {
      state += 1
    }
    if (this.passwordLength(this.password) >= this.minPasswordLength) {
      state += 1
    }

    return state
  }

  isValid() {
    return (
      this.hasDigit(this.password) &&
      this.hasLetter(this.password) &&
      this.passwordLength(this.password) >= this.minPasswordLength
    )
  }

  hasLetter() {
    return this.password.match(/[a-zA-Z]/g)
  }

  hasDigit() {
    return this.password.match(/[0-9]/g)
  }

  passwordLength() {
    return this.password.length
  }

  hideProgressbar() {
    this.$passwordValidationProgressbar.fadeOut()
    this.$passwordValidationInfoField.fadeOut()
  }

  showProgressbar() {
    this.$passwordValidationProgressbar.fadeIn()
    this.$passwordValidationInfoField.fadeIn()
  }

  enableSubmit() {
    this.$submitButton.prop('disabled', false)
    this.$submitButton.css('opacity', '1')
  }

  disableSubmit() {
    this.$submitButton.prop('disabled', true)
    this.$submitButton.css('opacity', '0.5')
  }
}
