/* global $ */
import ABCookieManager from '../lib/ab_cookie_manager'
import AbPubSub from '../lib/ab_pubsub'
import AbstractSearch from '../../../widgets/form/abstract_search/script'
import AccountActivationWidget from '../../../widgets/modal/account_activation/script'
import AdminBar from '../../../widgets/admin_bar/script'
import Autocomplete from '../lib/autocomplete'
import EmailUpdateWidget from '../../../widgets/modal/email_update/script'
import FlashMessagesWidget from '../../../widgets/flash_messages/script'
import FormattedInput from '../lib/formatted_input'
import FormattedPhoneNumberInput from '../lib/formatted_phone_number_input'
import GlobalWindowEventManager from '../lib/global_window_event_manager'
import MirrorSearch from '../lib/mirrorsearch'
import ModalLoginWidget from '../../../widgets/modal/login/script'
import ModalRegisterWidget from '../../../widgets/modal/register/script'
import ModalWidget from '../../../widgets/modal/main/script'
import MoveToTopButton from '../../../widgets/move_to_top_button/script'
import NavigationManager from '../lib/navigation_manager'
import NavigationSearchBar from '../../../widgets/navigation/main/search_bar/script'
import NavigationWidget from '../../../widgets/navigation/main/script'
import RemoveErrors from '../lib/remove_errors'
import ScrollTo from '../lib/scrollto'
import DatalayerPusher from '../lib/datalayer_pusher'
import ContactFormWidget from '../../../widgets/contact_form/script'

export default class Page {
  constructor() {
    this.pubSub = AbPubSub.getInstance()
    this.cookie = ABCookieManager.getInstance()
    new GlobalWindowEventManager(this.pubSub)

    // we should initialize widgets and other scripts, that are used almost everywhere here\
    new AbstractSearch()
    new AccountActivationWidget()
    new AdminBar()
    new Autocomplete()
    new EmailUpdateWidget()
    new FlashMessagesWidget()
    new FormattedInput(this.pubSub)
    new FormattedPhoneNumberInput()
    new MirrorSearch()
    new ModalLoginWidget()
    new ModalRegisterWidget()
    new ModalWidget()
    new MoveToTopButton()
    new NavigationManager()
    new NavigationSearchBar()
    new NavigationWidget()

    new RemoveErrors()
    new ScrollTo()
    new DatalayerPusher()

    new ContactFormWidget()
    this.disabledGtmCount()
    this.setupBanner()
  }

  disabledGtmCount() {
    window.addEventListener(
      'load',
      () => {
        if (!window.google_tag_manager) {
          $.ajax({
            type: 'POST',
            url: '/ajax/increment_disabled_gtm_count',
          })
        }
      },
      false
    )
  }

  dataLayerForNonEu() {
    const alreadyPushed = localStorage.getItem('dl-push-for-non-eu')

    if (!alreadyPushed) {
      window.dataLayer.push({
        event: 'view_nonEU-LP_Banner',
      })

      localStorage.setItem('dl-push-for-non-eu', 'true')
    }
  }

  setupBanner() {
    const showBanner = $('body').data('show-banner')

    let bannerDisplayed = false

    if (showBanner) {
      const bannerHtml = `
        <div class="non-eu-banner">
          <h3>Ausbildung in Germany?</h3>
          <div>
            <p>We have special vacancies for you.</p>
            <a href="/ausbildung-in-germany">Find jobs</a>
          </div>
        </div>
      `

      const handleScroll = () => {
        if (!bannerDisplayed && $(window).scrollTop() > 0) {
          $('body').append(bannerHtml)
          bannerDisplayed = true
          this.dataLayerForNonEu()
          $(document).off('scroll.setupBanner', handleScroll)
        }
      }

      $(document).on('scroll.setupBanner', handleScroll)
    }
  }
}
